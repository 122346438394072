<template>
  <section id="apercu">
    <b-row>
      <b-col>
        <b-card style="margin-right:auto;margin-left:auto;">
          <h5 class="text-center mb-0">Messagerie</h5>
          <hr />
          <b-card id="scrollable_div" style="width:100%; margin-right:auto;margin-left:auto;max-height:60vh;overflow:scroll;scroll-behavior: smooth; margin-bottom:1rem;">

            <div class="d-flex justify-content-center mb-3">
              <b-spinner v-if="load" small type="grow"></b-spinner>
            </div>

            <div v-for="message in messages" :key="message.id" :disabled="load">

              <!-- MESSAGE ENVOYE PAR LE CLIENT -->
              <div class="mb-1" v-if="message.sent === 1" style="text-align:right;">
                <div style="background-color:#E4B3D3; display:inline-block;max-width:80%;padding-right:10px; padding-left:10px; padding-top:2px; padding-bottom:2px; border-radius: 10px;">
                  <video
                    style="width:100%;"
                    v-if="message.file != null && !message.file.includes('vidyard') && message.file !== ''"
                    controls>
                    <source
                      :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                      class="rounded mb-50"
                    >
                  </video>
                  <b-embed
                    v-else-if="message.file != null && message.file.includes('vidyard') && message.file !== ''"
                    type="iframe"
                    :src="`${message.file}`"
                    allowfullscreen
                    class="rounded mb-50"
                  />
                  <b-card-text>
                    <span v-if="message.file != null && message.file.includes('vidyard')">Lien du message : <a target="_blank ":href="message.file">cliquez-ici</a></span>
                    <span v-if="message.message != ''" class="mt-1" v-html="message.message"></span>
                  </b-card-text>
                </div>
                <div style="font-style: italic; font-size:0.8rem;">
                  Le {{ (new Date(message.date*1000)).getDate() }}/{{ (new Date(message.date*1000)).getMonth() + 1 }}/{{ (new Date(message.date*1000)).getFullYear() }} à {{ (new Date(message.date*1000)).getHours() }}:{{ (new Date(message.date*1000)).getMinutes() }}
                </div>
              </div>

              <!-- MESSAGE ENVOYE PAR CHARGE DE COMPTE -->
              <div class="mb-1" v-else>
                <div style="background-color:#29235C; display:inline-block;max-width:80%; padding-right:10px; padding-left:10px; padding-top:2px; padding-bottom:2px; border-radius: 10px;">
                  <video
                    style="width:100%;"
                    v-if="message.file != null && !message.file.includes('vidyard') && message.file !== ''"
                    controls>
                    <source
                      :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                      class="rounded mb-50"
                    >
                  </video>
                  <b-embed
                    v-else-if="message.file != null && message.file.includes('vidyard') && message.file !== ''"
                    type="iframe"
                    :src="`${message.file}`"
                    allowfullscreen
                    class="rounded mb-50"
                  />
                  <b-card-text style="color:#fff;">
                    <span class="mb-2" v-if="message.file != null && message.file.includes('vidyard')">Lien du message : <a style="color:white;" target="_blank ":href="message.file">cliquez-ici</a></span>
                    <span v-if="message.message != ''" class="mt-1" v-html="message.message"></span>
                  </b-card-text>
                </div>
                <div style="font-style: italic; font-size:0.8rem;">
                  {{ message.who }} : le {{ (new Date(message.date*1000)).getDate() }}/{{ (new Date(message.date*1000)).getMonth() + 1 }}/{{ (new Date(message.date*1000)).getFullYear() }} à {{ (new Date(message.date*1000)).getHours() }}:{{ (new Date(message.date*1000)).getMinutes() }}
                </div>
              </div>

            </div>
          </b-card>
          <hr class="mb-2">

          <el-tiptap
              placeholder="Votre message..."
              lang="fr"
              v-model="text"
              :extensions="extensions"
            />
          </div>

          <b-button
            @click="formSubmitted()"
            v-if="encours === null"
            variant="primary"
            class="mt-2"
          >
            Envoyer
          </b-button>
          <b-button
            v-if="encours != null"
            variant="primary"
            disabled
          >
            <b-spinner small type="grow"></b-spinner>
            En cours...
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import {
  BCard, BRow, BCol, BButton, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormFile, BCardText, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
} from 'element-tiptap';


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormFile,
    BCardText,
    BSpinner,
    BFormTextarea,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      page_message: 1,
      load: false,
      messages: [],
      ready: false,
      encours: null,
      edit: null,
      file: null,
      text: '',

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
      ],
    }
  },
  methods: {
    formSubmitted() {
      this.encours = 'hey'
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('message', this.text)
      this.message = null
      this.file = null
      this.load = true

      axios
        .post('https://app2.oscar-black.com/api/message', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.encours = null
          this.messages = response.data
          setTimeout(() => {
            this.scroll()
            this.load = false
          }, 1500)
          this.text = null
          this.file = null

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Votre message a bien été envoyé !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.encours = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erreur',
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    editEnCours(val) {
      this.edit = val
    },
    scroll() {
      var objDiv = document.getElementById("scrollable_div");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    handleScroll(event) {
      if(event.target.scrollTop == 0) {
        this.load = true
        this.page_message++;
        axios
          .get('https://app2.oscar-black.com/api/message/'+this.page_message)
          .then(response => {
            this.messages = response.data
            this.load = false
            setTimeout(() => { this.ready = true }, 500)
          })
          .catch(() => {})
      }
    },
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/message/'+this.page_message)
      .then(response => {
        this.messages = response.data
        setTimeout(() => {
          this.scroll()
        }, 1500)
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
      this.$nextTick(function () {
        document.getElementById("scrollable_div").addEventListener('scroll', this.handleScroll);
      })

  },
  destroyed () {
    document.getElementById("scrollable_div").removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
